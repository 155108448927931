<template>
  <div>
      <!-- Form Modal -->
      <b-modal
          id="modal-society-form"
          ref="myModal"
          centered
          no-close-on-backdrop
          no-close-on-esc
          size="lg"
          @ok="submit"
      >
          <!-- Custom Modal Header -->
          <template #modal-header="{ close }">
              <!-- Emulate built in modal header close button action -->
              <h5>Register Grower</h5>
              
              <feather-icon
                  class="ml-4 pr-0 cursor-pointer"
                  icon="XIcon"
                  size="16"
                  @click="close()"
              />
          </template>

          <!-- Custom Modal Footer -->
          <template #modal-footer="{ ok, cancel }">
              <b-button
                  size="sm"
                  variant="primary"
                  @click="ok()"
                  :disabled="saving"
              >
                  <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                  <span v-if="!saving">Save</span>
                  <span v-if="saving">Saving...</span>
              </b-button>
              <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="cancel()"
              >
                  Cancel
              </b-button>
          </template>

          <!-- Data Form in Modal Body -->
          <template #default="{}">
              <validation-observer ref="dataForm" #default="{ invalid }">
                  <b-form ref="form" @submit.stop.prevent="handleCreate">
                      <b-row>
                          <b-col cols="12" md="6">
                              <b-row>
                                  <b-col cols="12" md="6">
                                      <b-row>
                                          <b-col cols="12">
                                              <b-avatar
                                                  :src="require('@/assets/images/avatars/1.png')"
                                                  size="140px"
                                                  rounded
                                              />
                                          </b-col>

                                          <b-col cols="12" class="pt-16">
                                              <b-form-group labe-for="gender" label="Gender" style="cursor: pointer">
                                                  <validation-provider #default="{ errors }" name="Gender" rules="">
                                                      <b-form-select
                                                          size="sm"
                                                          v-model="grower.gender"
                                                          id="gender"
                                                          name="gender"
                                                          :state="errors.length > 0 ? false : null"
                                                      >
                                                          <b-form-select-option :value="null">Select...</b-form-select-option>
                                                          <b-form-select-option value="female">Female</b-form-select-option>
                                                          <b-form-select-option value="male">Male</b-form-select-option>
                                                      </b-form-select>
                                                      <small class="text-danger">{{ errors[0] }}</small>
                                                      <small class="text-danger" v-if="serverErrors && serverErrors.gender">{{ serverErrors.gender[0] }}</small>
                                                  </validation-provider>
                                              </b-form-group>
                                          </b-col>
                                      </b-row>
                                  </b-col>
                                  <b-col cols="12" md="6">
                                      <b-row>
                                          <b-col cols="12">
                                              <b-form-group labe-for="firstName" label="First Name" style="cursor: pointer">
                                                  <validation-provider #default="{ errors }" name="First Name" rules="">
                                                      <b-form-input
                                                          id="firstName"
                                                          v-model="grower.firstName"
                                                          name="firstName"
                                                          :state="errors.length > 0 ? false : null"
                                                          size="sm"
                                                      />
                                                      <small class="text-danger">{{ errors[0] }}</small>
                                                      <small class="text-danger" v-if="serverErrors && serverErrors.firstName">{{ serverErrors.firstName[0] }}</small>
                                                  </validation-provider>
                                              </b-form-group>
                                          </b-col>
                                      </b-row>
                                      <b-row class="mt-0">
                                          <b-col cols="12">
                                              <b-form-group labe-for="middleName" label="Middle Name" style="cursor: pointer">
                                                  <validation-provider #default="{ errors }" name="Middle Name" rules="">
                                                      <b-form-input
                                                          id="middleName"
                                                          v-model="grower.middleName"
                                                          name="middleName"
                                                          :state="errors.length > 0 ? false : null"
                                                          size="sm"
                                                      />
                                                      <small class="text-danger">{{ errors[0] }}</small>
                                                      <small class="text-danger" v-if="serverErrors && serverErrors.middleName">{{ serverErrors.middleName[0] }}</small>
                                                  </validation-provider>
                                              </b-form-group>
                                          </b-col>
                                      </b-row>
                                      <b-row>
                                          <b-col cols="12">
                                              <b-form-group labe-for="lastName" label="Last Name" style="cursor: pointer">
                                                  <validation-provider #default="{ errors }" name="Last Name" rules="">
                                                      <b-form-input
                                                          id="lastName"
                                                          v-model="grower.lastName"
                                                          name="lastName"
                                                          :state="errors.length > 0 ? false : null"
                                                          size="sm"
                                                      />
                                                      <small class="text-danger">{{ errors[0] }}</small>
                                                      <small class="text-danger" v-if="serverErrors && serverErrors.lastName">{{ serverErrors.lastName[0] }}</small>
                                                      <small class="text-danger" v-if="serverErrors && !serverErrors.lastName && serverErrors.person_id">{{ serverErrors.person_id[0] }}</small>
                                                  </validation-provider>
                                              </b-form-group>
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                              <b-row>
                                  <b-col cols="12" md="6">
                                      <b-form-group labe-for="mobile" label="Phone Number" style="cursor: pointer">
                                          <validation-provider #default="{ errors }" name="Phone Number" rules="">
                                              <b-form-input
                                                  id="mobile"
                                                  name="mobile"
                                                  v-model="grower.mobile"
                                                  :state="errors.length > 0 ? false : null"
                                                  size="sm"
                                                  placeholder="e.g 07XX XXX XXX"
                                              />
                                              <small class="text-danger">{{ errors[0] }}</small>
                                              <small class="text-danger" v-if="serverErrors && serverErrors.mobile">{{ serverErrors.mobile[0] }}</small>
                                          </validation-provider>
                                      </b-form-group>
                                  </b-col>

                                  <b-col cols="12" md="6">
                                      <b-row style="padding-top:28px;">
                                          <b-col cols="12">
                                              <b-form-checkbox
                                                  checked="false"
                                                  class="custom-control-success"
                                                  name="check-button"
                                                  switch
                                                  v-model="grower.isIF"
                                                  :disabled="client.type !== 0"
                                              >
                                                  <span class="switch-icon-left">
                                                      <feather-icon icon="CheckCircleIcon" />
                                                  </span>
                                                  <span>Individual Farmer</span>                                 
                                              </b-form-checkbox>
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                              <b-row>
                                  <b-col cols="12">
                                      <b-form-checkbox
                                          :checked="grower.isActive"
                                          class="custom-control-success"
                                          name="check-button"
                                          switch
                                          v-model="grower.isActive"
                                      >
                                          <span class="switch-icon-left">
                                              <feather-icon icon="CheckCircleIcon" />
                                          </span>
                                          <span>Is Active</span>                                 
                                      </b-form-checkbox>
                                  </b-col>
                              </b-row>
                          </b-col>
                          <b-col cols="12" md="6">
                              <b-row>
                                  <b-col cols="6">
                                      <b-form-group labe-for="type" label="Grower Type" style="cursor: pointer">
                                          <validation-provider #default="{ errors }" name="Grower Type" rules="">
                                              <b-form-select
                                                  size="sm"
                                                  v-model="grower.contract.type"
                                                  id="type"
                                                  name="type"
                                                  :state="errors.length > 0 ? false : null"
                                              >
                                                  <b-form-select-option :value="0">New</b-form-select-option>
                                                  <b-form-select-option :value="1">Continuing</b-form-select-option>
                                                  <b-form-select-option :value="2">Revived</b-form-select-option>
                                              </b-form-select>
                                              <small class="text-danger">{{ errors[0] }}</small>
                                              <small class="text-danger" v-if="serverErrors && serverErrors.type">{{ serverErrors.type[0] }}</small>
                                          </validation-provider>
                                      </b-form-group>
                                  </b-col>

                                  <b-col cols="12" md="6">
                                      <b-form-group labe-for="registrationNumber" label="Registration Number" style="cursor: pointer">
                                          <validation-provider #default="{ errors }" name="Registration Number" rules="">
                                              <b-form-input
                                                  id="registrationNumber"
                                                  v-model="grower.registrationNumber"
                                                  name="registrationNumber"
                                                  :state="errors.length > 0 ? false : null"
                                                  size="sm"
                                              />
                                              <small class="text-danger">{{ errors[0] }}</small>
                                              <small class="text-danger" v-if="serverErrors && serverErrors.registrationNumber">{{ serverErrors.registrationNumber[0] }}</small>
                                          </validation-provider>
                                      </b-form-group>
                                  </b-col>
                              </b-row>

                              <b-row>
                                  <b-col cols="12" v-if="''+client.type === '0'">
                                      <b-form-group
                                          labe-for="society_id"
                                          label="Society"
                                          style="cursor: pointer"
                                      >
                                          <validation-provider #default="{ errors }" name="Society" rules="">
                                              <b-form-select
                                                  size="sm"
                                                  v-model="grower.society_id"
                                                  id="society_id"
                                                  name="society_id"
                                                  :state="errors.length > 0 ? false : null"
                                              >
                                                  <b-form-select-option :value="null">Select...</b-form-select-option>
                                                  <b-form-select-option
                                                      v-for="society in societies"
                                                      :key="society.id"
                                                      :value="society.id"
                                                  >
                                                      {{ society.name.toUpperCase() }}
                                                  </b-form-select-option>
                                              </b-form-select>
                                              <small class="text-danger">{{ errors[0] }}</small>
                                              <small class="text-danger" v-if="serverErrors && serverErrors.society_id">{{ serverErrors.society_id[0] }}</small>
                                          </validation-provider>
                                      </b-form-group>
                                  </b-col>

                                  <!-- <b-col cols="12" v-if="''+client.type === '1'">
                                      <b-form-group
                                          labe-for="client_id" label="Society" style="cursor: pointer"
                                      >
                                          <validation-provider #default="{ errors }" name="Society" rules="">
                                              <b-form-select
                                                  size="sm"
                                                  id="client_id"
                                                  name="client_id"
                                                  v-model="grower.client_id"
                                                  :state="errors.length > 0 ? false : null"
                                              >
                                                  <b-form-select-option :value="client.id" selected disabled>
                                                      {{ client.name.trim().toUpperCase() }}
                                                  </b-form-select-option>
                                              </b-form-select>
                                          </validation-provider>
                                      </b-form-group>
                                  </b-col> -->
                              </b-row>

                              <b-row v-if="''+client.type === '1'" class="mb-1">
                                  <b-col cols="12">
                                      <location-filters
                                          size="sm"
                                          align="vertical"
                                          @filter="changeFilter"
                                      />
                                      <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{ serverErrors.location_id[0] }}</small>
                                  </b-col>
                              </b-row>

                              <b-row v-if="''+client.type === '1'">
                                  <b-col cols="12" md="12">
                                      <h6>Club Membership</h6>
                                      <hr style="color: #000000;">
                                      <b-row>
                                          <b-col cols="12" md="12">
                                              <location-filters
                                                  size="sm"
                                                  align="vertical"
                                                  @filter="clubFilter"
                                              />
                                          </b-col>
                                      </b-row>

                                      <b-row>
                                          <b-col cols="12" md="12">
                                              <b-form-group
                                                  labe-for="club_id"
                                                  label="Club"
                                                  style="cursor: pointer"
                                              >
                                                  <validation-provider #default="{ errors }" name="club_id" rules="">
                                                      <b-form-select
                                                          id="club_id"
                                                          name="club_id"
                                                          size="sm"
                                                          v-model="grower.club_id"
                                                          :state="errors.length > 0 ? false : null"
                                                      >
                                                          <b-form-select-option :value="null">Select...</b-form-select-option>
                                                          <b-form-select-option
                                                              v-for="club in clubs"
                                                              :key="club.id"
                                                              :value="club.id"
                                                          >
                                                              {{ club.name.toUpperCase() }}
                                                          </b-form-select-option>
                                                      </b-form-select>
                                                      <small class="text-danger">{{ errors[0] }}</small>
                                                      <small class="text-danger" v-if="serverErrors && serverErrors.club_id">{{ serverErrors.club_id[0] }}</small>
                                                  </validation-provider>
                                              </b-form-group>
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>

                              <b-row v-if="''+client.type === '0'">
                                  <b-col cols="6">
                                      <b-form-group labe-for="sector_id" label="Sector" style="cursor: pointer">
                                          <validation-provider #default="{ errors }" name="Sector" rules="">
                                              <b-form-select
                                                  size="sm"
                                                  v-model="grower.sector_id"
                                                  id="sector_id"
                                                  name="sector_id"
                                                  :state="errors.length > 0 ? false : null"
                                                  @change="populateClubs()"
                                              >
                                                  <b-form-select-option :value="null">Select...</b-form-select-option>
                                                  <b-form-select-option v-for="sector in sectors" :key="sector.id" :value="sector.id">{{ sector.code.toUpperCase() }}</b-form-select-option>
                                              </b-form-select>
                                              <small class="text-danger">{{ errors[0] }}</small>
                                              <small class="text-danger" v-if="serverErrors && serverErrors.sector_id">{{ serverErrors.type[0] }}</small>
                                          </validation-provider>
                                      </b-form-group>
                                  </b-col>

                                  <b-col cols="6">
                                      <b-form-group labe-for="club_id" label="Club" style="cursor: pointer">
                                          <validation-provider #default="{ errors }" name="Society" rules="">
                                              <b-form-select
                                                  size="sm"
                                                  v-model="grower.club_id"
                                                  id="club_id"
                                                  name="club_id"
                                                  :state="errors.length > 0 ? false : null"
                                              >
                                                  <b-form-select-option :value="null">Select...</b-form-select-option>
                                                  <b-form-select-option v-for="club in clubs" :key="club.id" :value="club.id">{{ club.name.toUpperCase() }}</b-form-select-option>
                                              </b-form-select>
                                              <small class="text-danger">{{ errors[0] }}</small>
                                              <small class="text-danger" v-if="serverErrors && serverErrors.club_id">{{ serverErrors.club_id[0] }}</small>
                                          </validation-provider>
                                      </b-form-group>
                                  </b-col>
                              </b-row>

                              <b-row v-if="client.type === 0">
                                  <b-col cols="3">
                                      <b-form-group labe-for="hectares" label="Hectares" style="cursor: pointer">
                                          <validation-provider #default="{ errors }" name="Hectares" rules="">
                                              <b-form-input
                                                  size="sm"
                                                  v-model="grower.contract.hectares"
                                                  id="hectares"
                                                  name="hectares"
                                                  :state="errors.length > 0 ? false : null"
                                              />
                                              <small class="text-danger">{{ errors[0] }}</small>
                                              <small
                                                  class="text-danger"
                                                  v-if="serverErrors && serverErrors['contract.hectares']"
                                              >
                                                  {{ serverErrors['contract.hectares'][0] }}
                                              </small>
                                          </validation-provider>
                                      </b-form-group>
                                  </b-col>

                                  <b-col cols="3">
                                      <b-form-group labe-for="labors" label="Labors" style="cursor: pointer">
                                          <validation-provider #default="{ errors }" name="Labors" rules="">
                                              <b-form-input
                                                  size="sm"
                                                  v-model="grower.contract.labors"
                                                  id="labors"
                                                  name="labors"
                                                  :state="errors.length > 0 ? false : null"
                                              />
                                              <small class="text-danger">{{ errors[0] }}</small>
                                              <small class="text-danger" v-if="serverErrors && serverErrors.labors">{{ serverErrors.labors[0] }}</small>
                                          </validation-provider>
                                      </b-form-group>
                                  </b-col>

                                  <b-col cols="3">
                                      <b-form-group labe-for="motherBeds" label="M/Beds" style="cursor: pointer">
                                          <validation-provider #default="{ errors }" name="M/Beds" rules="">
                                              <b-form-input
                                                  size="sm"
                                                  v-model="grower.contract.motherBeds"
                                                  id="motherBeds"
                                                  name="motherBeds"
                                                  :state="errors.length > 0 ? false : null"
                                              />
                                              <small class="text-danger">{{ errors[0] }}</small>
                                              <small class="text-danger" v-if="serverErrors && serverErrors.motherBeds">{{ serverErrors.motherBeds[0] }}</small>
                                          </validation-provider>
                                      </b-form-group>
                                  </b-col>

                                  <b-col cols="3">
                                      <b-form-group labe-for="resetBeds" label="R/Beds" style="cursor: pointer">
                                          <validation-provider #default="{ errors }" name="R/Beds" rules="">
                                              <b-form-input
                                                  size="sm"
                                                  v-model="grower.contract.resetBeds"
                                                  id="resetBeds"
                                                  name="resetBeds"
                                                  :state="errors.length > 0 ? false : null"
                                              />
                                              <small class="text-danger">{{ errors[0] }}</small>
                                              <small class="text-danger" v-if="serverErrors && serverErrors.resetBeds">{{ serverErrors.resetBeds[0] }}</small>
                                          </validation-provider>
                                      </b-form-group>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-form>
              </validation-observer>

              <b-card v-if="client.type === 0">
                  <b-card-sub-title>
                      <b-row>
                          <b-col cols="9">
                              Barns
                          </b-col>
                          <b-col cols="3">
                              <b-button
                                  size="sm"
                                  variant="outline-success"
                                  @click="addRow"
                              >
                                  <feather-icon
                                      icon="PlusCircleIcon"
                                      class="mr-50"
                                  />
                                  <span class="align-middle">Add Row</span>
                              </b-button>
                          </b-col>
                      </b-row>
                  </b-card-sub-title>
                  <b-card-body>
                      <table class="table table-sm table-condensed" width="100%">
                          <thead>
                              <tr>
                                  <th width="3%"></th>
                                  <th class="whitespace-nowrap" width="15%">Length</th>
                                  <th class="whitespace-nowrap" width="15%">Width</th>
                                  <th class="whitespace-nowrap" width="15%">Tiers</th>
                                  <th class="whitespace-nowrap" width="12%">Slt?</th>
                                  <th class="whitespace-nowrap" width="12%">TV?</th>
                                  <th class="whitespace-nowrap" widht="12%">BV?</th>
                                  <th class="whitespace-nowrap" width="19%">Completed?</th>
                                  <th></th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr v-for="(barn, index) in grower.contract.barns" :key="index">
                                  <td align="center">
                                      {{ index + 1 }}
                                  </td>
                                  <td>
                                      <div class="col-span-12 sm:col-span-12">
                                          <b-form-input
                                              type="number"
                                              size="sm"
                                              name="length"
                                              v-model="grower.contract.barns[index].length"
                                          />
                                      </div>
                                  </td>
                                  <td>
                                      <div class="col-span-12 sm:col-span-12">
                                          <b-form-input
                                              type="number"
                                              size="sm"
                                              name="width"
                                              v-model="grower.contract.barns[index].width"
                                          />
                                      </div>
                                  </td>
                                  <td>
                                      <b-form-input
                                          type="number"
                                          size="sm"
                                          name="tiers"
                                          v-model="grower.contract.barns[index].tiers"
                                      />
                                  </td>
                                  <td>
                                      <b-form-checkbox
                                          checked="false"
                                          class="custom-control-success"
                                          name="slot"
                                          switch
                                          v-model="grower.contract.barns[index].slot"
                                      >
                                          <span class="switch-icon-left">
                                              <feather-icon icon="CheckCircleIcon" />
                                          </span>                              
                                      </b-form-checkbox>
                                  </td>
                                  <td>
                                      <b-form-checkbox
                                          checked="false"
                                          class="custom-control-success"
                                          name="topVent"
                                          switch
                                          v-model="grower.contract.barns[index].topVent"
                                      >
                                          <span class="switch-icon-left">
                                              <feather-icon icon="CheckCircleIcon" />
                                          </span>                              
                                      </b-form-checkbox>
                                  </td>
                                  <td>
                                      <b-form-checkbox
                                          checked="false"
                                          class="custom-control-success"
                                          name="bottomVent"
                                          switch
                                          v-model="grower.contract.barns[index].bottomVent"
                                      >
                                          <span class="switch-icon-left">
                                              <feather-icon icon="CheckCircleIcon" variant="danger" />
                                          </span>                              
                                      </b-form-checkbox>
                                  </td>
                                  <td align="center">
                                      <b-form-checkbox
                                          checked="false"
                                          class="custom-control-success"
                                          name="isComplete"
                                          switch
                                          v-model="grower.contract.barns[index].isComplete"
                                      >
                                          <span class="switch-icon-left">
                                              <feather-icon icon="CheckCircleIcon" />
                                          </span>                              
                                      </b-form-checkbox>
                                  </td>
                                  <td>
                                      <span
                                          title="Delete Barn"
                                          style="cursor:pointer"
                                          @click="removeRow(index)"
                                      >
                                          <feather-icon class="ml-4 pr-0 cursor-pointer" size="16" icon="Trash2Icon" />
                                      </span>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </b-card-body>
              </b-card>
              <!-- <b-card>
                  {{ grower }}
              </b-card> -->
          </template>
      </b-modal>
      <!-- ./Form Modal -->

      <!-- Filters -->
      <b-card no-body>
          <b-card-header class="pb-50">
              <h5>Filter Location</h5>
          </b-card-header>
          <b-card-body>
              <b-row>
                  <b-col cols="12" md="6" class="mb-md-0 mb-2">
                    <b-row>
                      <b-col cols="12" md="12" class="mb-md-0 mb-2">
                          <location-filters
                              size="sm"
                              align="horizontal"
                              @filter="changeFilter"
                          />
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col
                      cols="12"
                      md="3"
                      class="mb-md-0 mb-2"
                  >
                      <label>
                          Size: {{ parseFloat(file.size).toFixed(1) }}{{ file.unit }}/30MB 
                          <small v-if="serverErrors && serverErrors.fileInput" style="color: tomato">
                              {{ serverErrors.fileInput[0] }}
                          </small>
                      </label><br />
                      <b-button
                          size="sm"
                          variant="outline-success"
                          @click="openFileBrowser()"
                          :disabled="uploading"
                      >
                          <b-spinner small class="mr-1" label="Small Spinner" v-if="uploading" />
                          <span v-if="uploading"> Uploading...</span>
                          <span v-if="!uploading">Upload Growers</span>
                          <feather-icon icon="UploadCloudIcon" class="ml-1"/>
                      </b-button><br />
                      {{ file.orginalName }}
                      <input ref="fileInput" type="file" @change="convertFile" style="visibility: hidden;" />
                  </b-col>
                  <b-col cols="12" md="3" class="pt-2" v-if="client.type == 1">
                      <b-button
                          variant="flat-success"
                          class="btn-icon float-right"
                          size="sm"
                          title="Download Excel"
                          @click="downloadList(true)"
                      >
                          <img src="@/assets/images/icons/export_xls.png" width="24" height="24" />
                      </b-button>

                      <b-button
                          variant="flat-danger"
                          class="btn-icon mr-1 float-right"
                          size="sm"
                          title="Download pdf"
                          @click="downloadList(false)"
                      >
                          <img src="@/assets/images/icons/pdf_export.png" width="24" height="24" />
                      </b-button>
                  </b-col>
              </b-row>
              <!-- <b-card>{{ file }}</b-card> -->
          </b-card-body>
      </b-card>
      <!-- ./Filters -->

      <!-- Table Container Card -->
      <b-card no-body class="mb-0">
          <div class="m-2">
              <!-- Table Top -->
              <b-row>
                  <!-- Per Page -->
                  <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                      <label>Show</label>
                      <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="perPageOptions" :clearable="false"
                          class="per-page-selector d-inline-block mx-50 select-size-sm" />
                      <label>entries</label>
                  </b-col>
                  <!-- ./Per Page -->

                  <!-- Search & Button -->
                  <b-col cols="12" md="6">
                      <div class="d-flex align-items-center justify-content-end">
                          <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." size="sm" />
                          <b-button
                              variant="primary"
                              size="sm"
                              @click="invokeCreateForm()"
                          >
                              <span class="text-nowrap">Register Grower</span>
                          </b-button>
                      </div>
                  </b-col>
                  <!-- ./Search & Button-->
              </b-row>
              <!-- ./Table Top -->
          </div>

          <!-- Table -->
          <b-table
              striped
              hover
              small
              ref="records"
              class="position-relative"
              :items="fetch"
              responsive
              :fields="columns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No records found"
              :sort-desc.sync="isSortDirDesc"
          >
              <!-- Dynamic change the address column to final level -->
              <template #head(address)="data">
                  <span v-if="selections.length > 0">{{ selections[selections.length - 1].label }}</span>
              </template>

              <!-- Extract columns data -->
              <template #cell(name)="data">
                  <b-media vertical-align="center">
                      <template #aside>
                        <b-avatar
                          size="32"
                          :src="data.item.avatar"
                          :text="avatarText(data.item.person.firstName + ' ' + data.item.person.lastName)"
                          :variant="`light-${resolveGrowerTypeVariant(data.item.society)}`"
                          :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
                        />
                      </template>
                      <b-link
                        :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
                        class="font-weight-bold d-block text-nowrap"
                      >
                        {{ data.item.person.firstName }} {{ data.item.person.middleName }} {{ data.item.person.lastName }}
                      </b-link>
                      <small class="text-muted">Phone: {{ data.item.mobile }}</small>
                    </b-media>
              </template>

              <template #cell(gender)="data">
                  {{ data.item.person.gender ? data.item.person.gender.substr(0, 1).toUpperCase() + data.item.person.gender.substr(1).toLowerCase() : 'Unkwon' }}
              </template>

              <template #cell(registrationNumber)="data">
                  <span v-if="data.item.registrationNumber">{{ data.item.registrationNumber.toUpperCase() }}</span>
              </template>

              <template #cell(type)="data">
                  <span v-if="data.item.contracts && data.item.contracts.length > 0 && data.item.contracts[0].type === 0">New</span>
                  <span v-if="data.item.contracts && data.item.contracts.length > 0 && data.item.contracts[0].type === 1">Continuing</span>
                  <span v-if="data.item.contracts && data.item.contracts.length > 0 && data.item.contracts[0].type > 1">Revived</span>
                  <span v-if="!data.item.contracts || data.item.contracts.length === 0">Continuing</span>
              </template>

              <template #cell(society)="data">
                  <span v-if="data.item.society">{{ (data.item.society.code ? data.item.society.code.toUpperCase() + ': ' : '') + data.item.society.name.toUpperCase() }}</span>
                  <span v-else>Individual Farmer</span>
              </template>

              <template #cell(address)="data">
                  <span v-if="data.item.address">
                      {{ data.item.address.location.name.trim().toUpperCase() }}
                  </span>
              </template>

              <template #cell(sector)="data">
                  <span v-if="data.item.sector">{{ data.item.sector.code }}</span>
              </template>

              <template #cell(club)="data">
                  <span v-if="data.item.club">
                      <span v-if="data.item.club.code">{{ data.item.club.code.toUpperCase() }}: {{ data.item.club.name.toUpperCase() }}</span>
                      <span v-else>{{ data.item.club.name.toUpperCase() }}</span>
                  </span>
              </template>

              <template #cell(hectares)="data">
                  <span v-if="data.item.contracts && data.item.contracts.length > 0">{{ parseFloat(data.item.contracts[0].hectares).toFixed(1) }}</span>
              </template>

              <template #cell(labors)="data">
                  <span v-if="data.item.contracts && data.item.contracts.length > 0">{{ data.item.contracts[0].labors }}</span>
              </template>

              <template #cell(motherBeds)="data">
                  <span v-if="data.item.contracts && data.item.contracts.length > 0">{{ data.item.contracts[0].motherBeds }}</span>
              </template>

              <template #cell(resetBeds)="data">
                  <span v-if="data.item.contracts && data.item.contracts.length > 0">{{ data.item.contracts[0].resetBeds }}</span>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                  <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                  >

                      <template #button-content>
                      <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                      />
                      </template>
      
                      <b-dropdown-item :to="{ path: 'growers/' + data.item.id }">
                          <feather-icon icon="EyeIcon" />
                          <span class="align-middle ml-50">View</span>
                      </b-dropdown-item>

                      <b-dropdown-item @click="invokeUpdateForm(data.item)">
                          <feather-icon icon="EditIcon" />
                          <span class="align-middle ml-50">Edit</span>
                      </b-dropdown-item>
      
                      <b-dropdown-item @click="remove(data.item.id)">
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50">Delete</span>
                      </b-dropdown-item>
                  </b-dropdown>
              </template>
          </b-table>

          <!-- Table Footer -->
          <div class="mx-2 mb-2">
              <b-row>
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                      <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                      <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRecords"
                          :per-page="perPage"
                          first-number
                          last-number
                          class="mb-0 mt-1 mt-sm-0"
                          prev-class="prev-item"
                          next-class="next-item"
                      >
                          <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18" />
                          </template>
                          <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18" />
                          </template>
                      </b-pagination>
                  </b-col>
              </b-row>
          </div>

      </b-card>
      <!-- ./Table Container Card -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BModal, VBModal, BButton, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination, BSpinner, BMedia, BAvatar, BLink,
  BForm, BFormGroup, BFormSelect, BFormSelectOption, BFormInput,
  BFormCheckbox,
  BCard, BCardHeader, BCardSubTitle, BCardBody,
} from 'bootstrap-vue'
import LocationFilters from '@core/components/cromis/filters/LocationFilters.vue'
import { required, integer } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import growersStoreModule from '@/views/cromis/grower/growersStoreModule'
import useGrowersList from '@/views/cromis/grower/useGrowersList'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: {},
  components: {
      BCard,
      BCardHeader,
      BCardSubTitle,
      BCardBody,
      BRow,
      BCol,
      BForm,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BFormSelectOption,
      BFormCheckbox,
      BModal,
      BButton,
      BSpinner,
      BMedia,
      BLink,
      BAvatar,
      BTable,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,

      vSelect,
      ValidationProvider,
      ValidationObserver,

      LocationFilters,
  },
  directives: {
      'b-modal': VBModal,
  },
  
  setup (props, context) {
      const toast = useToast()
      const dataForm = ref(null)
      const myModal = ref(null)
      const fileInput = ref(null)
      const saving = ref(false)
      const uploading = ref(false)
      const serverErrors = ref(null)

      const selections = ref([])
      const levels = ref(null)
      const children = ref([])
      const societies = ref([])
      const sectors = ref([])
      const clubs = ref([])

      const client = ref(JSON.parse(localStorage.getItem('userData')).client)

      const file = ref({
          document: null,
          type: null,
          size: 0,
          unit: 'KB',
          originalName: null,
          location_id: null,
          contents: 'growers',
      })

      const grower = ref({
          id: null,
          firstName: null,
          middleName: null,
          lastName: null,
          gender: null,
          mobile: null,
          isIF: client.value.type !== 1? false : true,
          registrationNumber: null,
          society_id: '' + client.value.type == '1'? client.value.id : null,
          client_id: '' + client.value.type !== '0'? client.value.id : null,
          sector_id: null,
          club_id: null,
          isActive: true,
          location_id: null,
          contract: client.value.type === 0? {
              hectares: 0,
              labors: 1,
              type: 1,
              motherBeds: 0,
              resetBeds: 0,
              barns: [
                  {
                      length: 0,
                      width: 0,
                      tiers: 0,
                      topVent: false,
                      bottomVent: false,
                      slot: false,
                      isComplete: false
                  }
              ],
          } : {
              hectares: 0,
              labors: 1,
              type: 1,
              motherBeds: 0,
              resetBeds: 0,
              barns: [],
          },
      })

      const CROMIS_GROWER_STORE_MODULE_NAME = 'cromis-grower'

      // Register module
      if (!store.hasModule(CROMIS_GROWER_STORE_MODULE_NAME)) store.registerModule(CROMIS_GROWER_STORE_MODULE_NAME, growersStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
      if (store.hasModule(CROMIS_GROWER_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_GROWER_STORE_MODULE_NAME)
      })

      const isAddNewUserSidebarActive = ref(false)

      onMounted(async () => {
          await store.dispatch('cromis-grower/levels')
                  .then(response => {
                      levels.value = response.data.levels[0]

                      selections.value.push({
                          level: levels.value.name.toLowerCase(),
                          model: null,
                          label: levels.value.name,
                          child: levels.value.children.length > 0? levels.value.children[0].name.toLowerCase() : null,
                          locations: [],
                      })

                      let list = levels.value.children

                      while(list.length > 0){
                          children.value.push(list[0])
                          if(list[0].children > 0){
                              selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                          }
                          else{
                              selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                          }

                          list = list[0].children
                      }
                  })
                  .catch(error => {
                      console.log('got error', error)
                  })

          await store.dispatch('cromis-grower/locations')
                      .then(response => {
                          populateSelections(response.data.locations)
                      })
                      .catch(error => {
                          console.log('got error', error)
                      })
      })

      const populateSelections = (locations) => {
          for(let i = 0; i < locations.length; i++){
              for(let x = 0; x < selections.value.length; x++){
                  if(locations[i].level.name.toLowerCase() === selections.value[x].level){
                      selections.value[x].locations.push(locations[i])
                  }
              }
          }
      }

      const changeFilter = (filteredValue) => {
          console.log(filteredValue)
          grower.value.location_id = filteredValue != null? filteredValue.id : null
          locationFilter.value = filteredValue != null? filteredValue.id : null
      }

      const clubFilter = async (filteredValue) => {
          await store.dispatch('cromis-grower/clubs', { location_id: filteredValue != null? filteredValue.id : null })
                              .then(response => {
                                  clubs.value = response.data.clubs
                              })
                              .catch(error => {
                                  console.log('got error', error)
                              })
      }

      const downloadList = async (isExcel) => {
          console.log(isExcel)
          let type = isExcel? 1 : 0
          console.log(client.value)
          await window.open(`${ axios.defaults.baseURL }/reports/markets_list?view=${ client.value.id }&type=${ type }&location_id=${ locationFilter.value }`, '_blank')
      }

      // const populateClubs = async () => {
      //     await store.dispatch('cromis-grower/clubs', { sector_id: grower.value.sector_id })
      //                         .then(response => {
      //                             clubs.value = response.data.clubs
      //                         })
      //                         .catch(error => {
      //                             console.log('got error', error)
      //                         })
      // }

      const openFileBrowser = () => {
          fileInput.value.click()
      }

      const convertFile = (e) => {
          file.value = {
              document: null,
              type: null,
              size: 0,
              unit: 'KB',
              originalName: null,
              location_id: null,
              contents: 'growers',
          }

          let rawFile = e.target.files[0]

          file.value.originalName = rawFile.name
          file.value.size = rawFile.size / 1048576 < 1 ?
                                  rawFile.size / 1024 : rawFile.size / 1048576
          file.value.unit = rawFile.size / 1048576 < 1 ? 'KB' : 'MB'
          file.value.type = rawFile.type

          serverErrors.value = null
          uploading.value = false

          if(file.value.size > 30 && file.value.unit === 'MB'){
              serverErrors.value = { fileInput: [
                  'File size exceeded (Max 30MB)'
              ] }
          }
          else{
              let name = file.value.originalName
              if(!['csv', 'xls', 'xlsx'].includes(name.substring(name.indexOf('.') + 1))){
                  serverErrors.value = { fileInput: [
                  'Invalid file type, required excel file (csv, xlsx or xls)'
              ] }
              }
          }

          let reader = new FileReader()

          reader.onloadend = async () => {
              file.value.document = await reader.result

              if(!serverErrors.value || !serverErrors.value.fileInput){
                  // file.value.location_id = selections.value[selections.value.length - 1].model.id
                  uploading.value = true
                  await store.dispatch('cromis-grower/imports', file.value)
                                  .then(response => {
                                      refetch()
                                      uploading.value = false

                                      file.value = {
                                          document: null,
                                          type: null,
                                          size: 0,
                                          unit: 'KB',
                                          originalName: null,
                                          location_id: null,
                                          content: 'growers',
                                      }
                                      
                                      toast({
                                          component: ToastificationContent,
                                          props: {
                                              title: response.data.message,
                                              icon: 'AlertTriangleIcon',
                                              variant: 'success',
                                          },
                                      })
                                  })
                                  .catch(error => {
                                      uploading.value = false
                                      context.root.$swal({
                                          icon: 'error',
                                          title: `${ error.response.status } ${ error.response.statusText }`,
                                          text: error.response.data.message,
                                          showConfirmButton: true,
                                          customClass: {
                                              confirmButton: 'btn btn-outline-danger',
                                          },
                                          buttonsStyling: false,
                                      })
                                  })
              }
              else{
                  uploading.value = false
              }
          }

          reader.readAsDataURL(rawFile)
      }

      //Methods for dynamic table rows
      const addRow = () => {
          let barn = {
              length: 0,
              width: 0,
              tiers: 0,
              slot: false,
              bottomVent: false,
              topVent: false,
              isComplete: false
          }
          grower.value.contract.barns.push(barn)
      }

      const removeRow = (index) => {
          grower.value.contract.barns.splice(index, 1)
      }

      const {
          fetch,
          columns,
          perPage,
          currentPage,
          totalRecords,
          from,
          to,
          meta,
          perPageOptions,
          searchQuery,
          sortBy,
          isSortDirDesc,
          records,
          refetch,

          // UI
          // resolveUserRoleVariant,
          // resolveUserRoleIcon,
          resolveGrowerTypeVariant,
          resolveStatusVariant,

          // Filers
          locationFilter,
      } = useGrowersList()

      // Methods
      const invokeUpdateForm = async (item) => {
          // console.log(item)
          // grower.value = item

          let barns = [];

          if(client.value.type == 0){
              for(let i = 0; i < item.contracts[item.contracts.length - 1].barns.length; i++){
                  barns.push({
                      length: item.contracts[item.contracts.length - 1].barns[i].length,
                      width: item.contracts[item.contracts.length - 1].barns[i].width,
                      tiers: item.contracts[item.contracts.length - 1].barns[i].tiers,
                      topVent: !!item.contracts[item.contracts.length - 1].barns[i].topVent,
                      bottomVent: !!item.contracts[item.contracts.length - 1].barns[i].bottomVent,
                      slot: !!item.contracts[item.contracts.length - 1].barns[i].slot,
                      isComplete: !!item.contracts[item.contracts.length - 1].barns[i].isComplete
                  })
              }
          }

          grower.value = {
              id: item.id,
              firstName: item.person.firstName,
              middleName: item.person.middleName,
              lastName: item.person.lastName,
              gender: item.person.gender,
              mobile: item.mobile,
              isIF: item.isIF,
              registrationNumber: item.registrationNumber,
              society_id: client.value.type == 0? item.society.id : (client.value.type == 1? client.value.id : null),
              client_id: client.value.id,
              sector_id: client.value.type == 0? item.sector.id : null,
              club_id: client.value.type == 0? item.club.id : null,
              isActive: item.isActive,
              location_id: selections.value[selections.value.length - 1].model !== null? selections.value[selections.value.length - 1].model.id : null,
              contract: client.value.type == 0? {
                  hectares: item.contracts[item.contracts.length - 1].hectares,
                  labors: item.contracts[item.contracts.length - 1].labors,
                  type: item.contracts[item.contracts.length - 1].type,
                  motherBeds: item.contracts[item.contracts.length - 1].motherBeds,
                  resetBeds: item.contracts[item.contracts.length - 1].resetBeds,
                  barns: barns,
              }: {
                  hectares: 1,
                  labors: 1,
                  type: 1,
                  motherBeds: 1,
                  resetBeds: 1,
                  barns: [],
              }
          }

          await populateClubs()

          myModal.value.show()
      }

      const invokeCreateForm = () => {
          grower.value = {
              id: null,
              firstName: null,
              middleName: null,
              lastName: null,
              gender: null,
              isIF: client.value.type !== 1? false : true,
              registrationNumber: null,
              society_id: '' + client.value.type == '1'? client.value.id : null,
              client_id: '' + client.value.type !== '0'? client.value.id : null,
              sector_id: null,
              club_id: null,
              isActive: true,
              location_id: selections.value[selections.value.length - 1].model !== null? selections.value[selections.value.length - 1].model.id : null,
              contract: client.value.type === 0? {
                  hectares: 0,
                  labors: 1,
                  type: 1,
                  motherBeds: 0,
                  resetBeds: 0,
                  barns: [
                      {
                          length: 0,
                          width: 0,
                          tiers: 0,
                          topVent: false,
                          bottomVent: false,
                          slot: false,
                          isComplete: false
                      }
                  ],
              } : {
                  hectares: 0,
                  labors: 1,
                  type: 1,
                  motherBeds: 0,
                  resetBeds: 0,
                  barns: [],
              }
          }

          myModal.value.show()
      }

      const isFormValid = async () => {
          let isValid = false 
          await dataForm.value.validate().then(success => {
              isValid = success
          })

          return isValid
      }

      const submit = (bvModalEvt) => {
          bvModalEvt.preventDefault()

          serverErrors.value = null
          // Handle form submit
          if (grower.value.id === null || grower.value.id === 0)
              handleCreate()
          else
              handleUpdate(grower.value)
      }

      const handleCreate = async () => {
          saving.value = true
          const valid = await isFormValid()

          if(!valid){
              saving.value = false
              return
          }

          
          await store.dispatch('cromis-grower/create', grower.value)
              .then(response => {
                  refetch()
                  saving.value = false

                  myModal.value.hide()

                  context.root.$swal({
                      icon: 'success',
                      text: `Grower ${response.data.person.firstName} ${response.data.person.lastName} has been saved successfully!`,
                      showConfirmButton: true,
                      timer: 5000,
                      customClass: {
                          confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                  })
              })
              .catch(error => {
                  saving.value = false
                  if(error.response.status === 422){
                      serverErrors.value = error.response.data.errors
                  }
                  else{
                      context.root.$swal({
                          icon: 'error',
                          title: 'Server Error',
                          text: 'Something went wrong. See tech support',
                          showConfirmButton: true,
                          customClass: {
                              confirmButton: 'btn btn-danger',
                          },
                          buttonsStyling: false,
                      })
                  }
              })
      }

      const handleUpdate = async (item) => {
          saving.value = true
          const valid = await isFormValid()

          if(!valid){
              saving.value = false
              return
          }

          console.log('item', item)

          await store.dispatch('cromis-grower/update', { id: item.id, data: item })
              .then(response => {
                  refetch()
                  saving.value = false

                  myModal.value.hide()

                  context.root.$swal({
                      icon: 'success',
                      text: `Changes to grower ${response.data.person.firstName} ${response.data.person.lastName} has been saved successfully!`,
                      showConfirmButton: false,
                      timer: 5000,
                      customClass: {
                          confirmButton: 'btn btn-success',
                      },
                      buttonsStyling: false,
                  })
              })
              .catch(error => {
                  saving.value = false
                  if(error.response.status === 422){
                      serverErrors.value = error.response.data.errors
                  }
                  else{
                      context.root.$swal({
                          icon: 'error',
                          title: 'Server Error',
                          text: 'Something went wrong. See tech support',
                          showConfirmButton: true,
                          customClass: {
                              confirmButton: 'btn btn-danger',
                          },
                          buttonsStyling: false,
                      })
                  }
              })
      }

      const remove = async (id) => {
          await store.dispatch('cromis-grower/remove', id)
                      .then(response => {
                          refetch()
                      })
                      .catch(error => {
                          context.root.$swal({
                              icon: 'error',
                              title: 'Server Error',
                              text: 'Something went wrong. See tech support',
                              showConfirmButton: false,
                              customClass: {
                                  confirmButton: 'btn btn-danger',
                              },
                              buttonsStyling: false,
                          })
                      })
      }

      return {
          client,

          // Sidebar
          isAddNewUserSidebarActive,

          fetch,
          columns,
          perPage,
          currentPage,
          totalRecords,
          from,
          to,
          meta,
          perPageOptions,
          searchQuery,
          sortBy,
          isSortDirDesc,
          records,
          refetch,

          // Filter
          avatarText,
          changeFilter,
          clubFilter,
          downloadList,
          openFileBrowser,
          convertFile,
          locationFilter,
          fileInput,
          file,

          // UI
          // resolveUserRoleVariant,
          // resolveUserRoleIcon,
          resolveGrowerTypeVariant,
          resolveStatusVariant,

          // Form Data
          grower,

          // Validations
          required,
          integer,

          // Methods and Form refs
          myModal,
          saving,
          uploading,
          serverErrors,
          dataForm,
          invokeCreateForm,
          invokeUpdateForm,
          isFormValid,
          submit,
          handleCreate,
          handleUpdate,
          remove,
          selections,
          levels,
          children,
          societies,
          sectors,
          clubs,
          removeRow,
          addRow,
      }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
